<template>
    <div class="breadcrumbs">
        <div v-for="link in links" class="breadcrumb" :key="link.url">
            <font-awesome-icon class="chevron" icon="chevron-right" />
            <router-link :to="link.url">
                {{ link.text }}
            </router-link>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Breadcrumbs",
        props: {
            links: Array
        }
    }
</script>

<style lang="scss" scoped>
    @import '../../assets/scss/variables';

    .breadcrumbs {
        background-color: $darkBlue;
        padding: $sectionPadding;

        .breadcrumb {
            display: inline;
            font-family: $bodyFont;
            font-size: 1rem;
            color: $white;
            text-transform: uppercase;
            padding-right: 0.75rem;
            vertical-align: middle;

            .chevron {
                margin-right: 0.5rem;
                vertical-align: middle;
            }

            a {
                color: inherit;
                text-decoration: none;
                vertical-align: middle;
            }

            &:first-child {

                .chevron {
                    font-size: 1.4rem;
                }
            }
        }
    }
</style>
