<template>
    <div v-if="studios.length < 1" class="loading">
        <font-awesome-icon icon="cog" />
    </div>

    <table v-else>
        <thead>
            <tr>
                <th>Studio</th>
                <th>Killed</th>
                <th>Murder Weapon</th>
                <th>Known For</th>
            </tr>
        </thead>

        <tbody>
            <tr v-for="studio in studios" :key="studio.id">
                <td>{{ studio.name }}</td>
                <td>{{ formatDate(studio.killed) }}</td>
                <td>{{ studio.murder_weapon }}</td>
                <td>{{ studio.known_for ? studio.known_for.join(', ') : '' }}</td>
            </tr>
        </tbody>
    </table>
</template>

<script>
    import moment from 'moment'

    export default {
        name: "StudiosKilledTable",
        props: {
            studios: Array
        },
        methods: {
            formatDate(date) {
                return moment(date).format('MMMM Do, YYYY')
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import '../../assets/scss/tables.scss';
</style>
