<template>
    <div class="page">
        <section class="total-sins text-center">
            <v-sprite :id="'total-sins-sprite'"
                      :spritesheet="require('../../assets/images/totalsins_spritesheet.png')"
                      :json="require('../../assets/images/totalsins_spritesheet.json')"
                      :fps="12" />
            <h1>
                <i-count-up
                    v-if="sinsTotal"
                    class="total-sins"
                    :start-val="0"
                    :end-val="sinsTotal"
                    :decimals="0"
                    :duration="3.5"
                    :options="{ useEasing: true, separator: ',' }" />
                <span v-else class="total-sins">---</span>

                Total Sins
            </h1>

            <router-link to="/sins" class="button">
                See List
            </router-link>
        </section>

        <section class="brand callouts">
            <div class="container">
                <div class="row">
                    <div class="col-12 col-sm-4">
                        <callout-box :image="require('../../assets/images/studioskilled.png')"
                                     link="/kill-count/studios">

                            <span v-if="studiosKilledTotal" class="count">{{ studiosKilledTotal }}</span>
                            <span v-else class="count">--</span>
                            <span class="title">Studios</span>
                            <span class="subtitle">Killed</span>
                        </callout-box>
                    </div>

                    <div class="col-12 col-sm-4">
                        <callout-box :image="require('../../assets/images/gameskilled.png')"
                                     link="/kill-count/games">

                            <span v-if="gamesKilledTotal" class="count">{{ gamesKilledTotal }}</span>
                            <span v-else class="count">--</span>
                            <span class="title">Games</span>
                            <span class="subtitle">Killed</span>
                        </callout-box>
                    </div>

                    <div class="col-12 col-sm-4">
                        <callout-box :image="require('../../assets/images/deathrow.png')"
                                     link="/kill-count/death-row">

                            <span v-if="gamesOnDeathRowTotal" class="count">{{ gamesOnDeathRowTotal }}</span>
                            <span v-else class="count">--</span>
                            <span class="title">Games</span>
                            <span class="subtitle smallest">on</span>
                            <span class="subtitle smaller">Death Row</span>
                        </callout-box>
                    </div>
                </div>
            </div>
        </section>

        <section class="about">
            <div class="container">
                <div class="row">
                    <div class="col-12 offset-lg-2 col-lg-8">
                        <h2>What is EASins?</h2>
                        <p>The purpose of this site is to chronicle and lay bare all of the wrongs that Electronic Arts has committed against gamers, developers, and the industry of gaming as a whole.</p>

                        <h2>What's a Sin?</h2>
                        <p>Okay, so what's the problem with EA? Crappy DLC? Bug-ridden releases? The faces in Mass Effect: Andromeda?</p>
                        <p>In short, no. These are valid criticisms of Electronic Arts, but it's not what this site focuses on. A Sin is more than just a disappointing release or low framerates; a Sin is disrespectful, abusive, or outright destructive. A Sin must destroy value, cheapen experiences, or deceive customers. It could abuse developers, or take advantage of consumers. It may harm culture or the industry of gaming. Above all, due to EA's position as a market leader, it contributes to making these practices commonplace - even accepted.</p>

                        <h2>Why EA?</h2>
                        <p>Everyone knows EA sucks, right? Why bother repeating what everyone already knows?</p>
                        <p>And yet, here we are. Electronic Arts remains a market leader, selling hundreds of thousands of games with every new release. Everyone knows EA's reputation; but it seems that not nearly enough people understand <span class="italic">why</span> that reputation exists, and why it is so thoroughly deserved.</p>
                        <p>The hope is that by starkly laying bare EA's history of abuse, gamers will finally start to realize how truly, unrepentantly ruthless, greedy, amoral, and just plain evil the company is.</p>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    import ICountUp from 'vue-countup-v2'
    import CalloutBox from '../CalloutBox'
    import EaSinsApi from '../../services/EaSinsApi'

    export default {
        name: 'Home',
        components: { ICountUp, CalloutBox },
        data: function () {
            return {
                sinsTotal: null,
                studiosKilledTotal: null,
                gamesKilledTotal: null,
                gamesOnDeathRowTotal: null
            }
        },
        created: function () {
            EaSinsApi.getSinsCount()
                .then((response) => {
                    this.sinsTotal = response.data.count
                })

            EaSinsApi.getStudiosKilledCount()
                .then((response) => {
                    this.studiosKilledTotal = response.data.count
                })

            EaSinsApi.getGamesKilledCount()
                .then((response) => {
                    this.gamesKilledTotal = response.data.count
                })

            EaSinsApi.getGamesOnDeathRowCount()
                .then((response) => {
                    this.gamesOnDeathRowTotal = response.data.count
                })
        }
    }
</script>

<style lang="scss" scoped>
    @import '../../assets/scss/variables.scss';

    section.total-sins {
        padding: ($sectionPadding * 3) $sectionPadding;

        .vue-sprite {
            padding: 0 0 $sectionPadding 0;
        }
    }

    h1 {

        span {
            display: block;
            font-size: 5rem;
            letter-spacing: 0;
        }
    }

    .callouts {
        padding-top: ($sectionPadding * 2);
    }

    .callout {
        text-align: center;
        margin-bottom: $sectionPadding;
        padding: $sectionPadding;
        border: 1px solid $white;

        h2 {
            color: $white;

            span {
                display: block;
            }

            span.count {
                font-size: 3rem;
            }

            span.title {
                font-size: 1.8rem;
            }

            span.subtitle {
                font-size: 1.4rem;

                &.smaller {
                    font-size: 0.87rem;
                }

                &.smallest {
                    font-size: 0.6rem;
                }
            }
        }
    }

    .about {

        h2 {
            text-align: center;
            color: $black;
            margin-top: ($sectionPadding * 3);
            letter-spacing: -1px;
            margin-bottom: $sectionPadding;
            width: 100%;

            &:first-child {
                margin-top: $sectionPadding;
            }
        }

        p {
            font-family: $bodyFont;
            font-size: 1.2rem;
            color: $black;
            margin-bottom: $sectionPadding;
            font-weight: 300;
            line-height: 1.2;
        }

        p:last-child {
            margin-bottom: ($sectionPadding * 2);
        }
    }
</style>
