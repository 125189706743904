import { render, staticRenderFns } from "./DeathRowTable.vue?vue&type=template&id=81b6fccc&scoped=true&"
import script from "./DeathRowTable.vue?vue&type=script&lang=js&"
export * from "./DeathRowTable.vue?vue&type=script&lang=js&"
import style0 from "./DeathRowTable.vue?vue&type=style&index=0&id=81b6fccc&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "81b6fccc",
  null
  
)

component.options.__file = "DeathRowTable.vue"
export default component.exports