<template>
    <div :class="('message ' + type)">
        <p>{{ message }}</p>
    </div>
</template>

<script>
    export default {
        name: 'MessageBox',
        props: {
            message: {
                type: String,
                required: true
            },
            type: {
                type: String,
                required: true,
                validator: function (value) {
                    return ['success', 'error'].indexOf(value) !== -1
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import '../../assets/scss/variables.scss';

    .message {
        padding: $sectionPadding;
        width: 100%;

        &.success {
            background-color: $success;
        }

        &.error {
            background-color: $error;
        }

        p {
            color: $white;
            margin: 0;
            font-family: $bodyFont;
        }
    }
</style>
