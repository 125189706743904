<template>
    <div id="app">
        <header class="container-fluid">
            <div class="row">
                <div class="col-6">
                    <router-link to="/">
                        <img class="logo" src="./assets/images/logo.png" alt="Logo" />
                        <span class="logo">Sins</span>
                    </router-link>
                </div>

                <div
                    class="col-6 text-right"
                    v-on-click-outside="closeNavMenu">

                    <nav-button
                            :is-open="navIsOpen"
                            :on-click="toggleNavMenu" />

                    <navigation
                            :is-open="navIsOpen"
                            :on-item-click="closeNavMenu" />
                </div>
            </div>
        </header>

        <router-view></router-view>

        <footer class="container-fluid">
            <div class="row">
                <nav class="col-12 col-md-6 text-center text-md-left">
                    <ul>
                        <li><router-link to="/">Home</router-link></li>
                        <li><router-link to="/sins">Sins</router-link></li>
                        <li><router-link to="/kill-count">Kill Count</router-link></li>
                    </ul>
                </nav>

                <div class="col-12 col-md-6 text-center text-md-right">
                    <div class="powered">
                        <span>Powered by</span>

                        <a target="_blank" href="https://vuejs.org/">
                            <img src="./assets/images/vue.png" alt="Vue"/>
                        </a>

                        <a target="_blank" href="https://firebase.google.com/">
                            <img src="./assets/images/firebase.png" alt="Firebase"/>
                        </a>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-12 text-center">
                    <span class="copyright">&copy; 2018 Christian Griffin. All rights reserved.</span>
                </div>
            </div>
        </footer>
    </div>
</template>

<script>
    import Navigation from './components/navigation/Navigation'
    import NavButton from './components/navigation/NavButton'

    export default {
        name: 'app',
        components: {
            Navigation, NavButton
        },
        data: function() {
            return {
                navIsOpen: false
            }
        },
        methods: {
            toggleNavMenu: function () {
                this.navIsOpen = !this.navIsOpen
            },
            closeNavMenu: function () {
                this.navIsOpen = false
            }
        }
    }
</script>

<style lang="scss">
    @import '../node_modules/reset-css/sass/reset.scss';
    @import '../node_modules/bootstrap/scss/mixins/_breakpoints.scss';
    @import '../node_modules/bootstrap/dist/css/bootstrap-grid.min.css';
    @import './assets/scss/fonts.scss';
    @import './assets/scss/variables.scss';
    @import './assets/scss/typography.scss';

    .text-right {
        text-align: right;
    }

    .text-left {
        text-align: left;
    }

    .text-center {
        text-align: center;
    }

    .italic {
        font-style: italic;
    }

    .button,
    .button-brand {
        padding: 0.25rem 1rem;
        font-family: $bodyFont;
        font-size: 1.2rem;
        text-transform: uppercase;
        display: inline-block;
        text-decoration: none;
        white-space: nowrap;
    }

    .button:focus, .button-brand:focus,
    .button:hover, .button-brand:hover,
    .button:active, .button-brand:active {
        outline: 0;
        text-decoration: none;
    }

    .button,
    .button-brand:hover {
        background-color: $brand;
        color: $white;
        transition: all 0.1s ease;
    }

    .button:hover,
    .button-brand {
        background-color: $white;
        color: $brand;
        transition: all 0.1s ease;
    }

    .button {
        border: 4px solid $brand;
    }

    .button-brand {
        border: 4px solid $white;
    }

    section {
        padding: $sectionPadding;
    }

    section.brand {
        background-color: $brand;

        h2, h3, h4 {
            color: $white;
        }
    }

    img {
        max-width: 100%;
    }

    body {
        background-color: $white;
    }

    #app {
        padding-top: ($sectionPadding * 2) + $headerContentSize;
    }

    header {
        background-color: $brand;
        padding: $headerPadding;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        z-index: 99;

        a {
            text-decoration: none;

            img.logo {
                position: absolute;
                top: -0.5rem;
                left: $sectionPadding;
                height: 3.25rem;
            }

            span.logo {
                font-family: $displayFont;
                font-size: $headerContentSize;
                color: $textOnBrand;
                padding-left: 3.5rem;

            }
        }
    }

    footer {
        background-color: $brand;
        padding: 0 0 $sectionPadding $sectionPadding;

        nav {

            ul {
                display: block;

                li {
                    display: inline-block;

                    a,
                    a:active,
                    a:focus {
                        display: inline-block;
                        background-color: $brandHover;
                        padding: ($sectionPadding / 2);
                        font-size: 1rem;
                        font-family: $bodyFont;
                        text-transform: uppercase;
                        margin: $sectionPadding $sectionPadding 0 0;
                        text-decoration: none;
                        color: $white;
                    }
                }
            }
        }

        .powered {
            padding: $sectionPadding 0;

            span {
                font-family: $bodyFont;
                text-transform: uppercase;
                color: $white;
                vertical-align: middle;
            }

            img {
                height: (1rem + $sectionPadding);
                vertical-align: middle;
                margin-left: ($sectionPadding / 2);
            }
        }

        .copyright {
            margin-top: $sectionPadding;
            display: inline-block;
            font-family: $bodyFont;
            font-weight: 300;
            color: $white;
        }
    }

    h1 {
        font-family: $displayFont;
        display: block;
        font-size: 3rem;
        color: $black;
        margin-bottom: ($sectionPadding / 2);
        text-transform: uppercase;
        letter-spacing: -3px;
        line-height: 0.9;
    }

    h2 {
        font-family: $displayFont;
        display: block;
        margin: ($sectionPadding / 2) auto;
        text-transform: uppercase;
        font-size: 2rem;
    }

    h3 {
        font-family: $displayFont;
        display: block;
        margin: 0 auto ($sectionPadding / 2);
        text-transform: uppercase;
        font-size: 1.2rem;
    }

    section.page-header {

        margin: ($sectionPadding * 2) 0;

        img {
            margin-bottom: $sectionPadding;
        }
    }

    table {
        margin-top: ($sectionPadding * 2);
        margin-bottom: ($sectionPadding * 2);
        width: 100%;
        font-family: $bodyFont;
        background: $white;
        table-layout: fixed;
        border: 0.25rem solid $brand;

        th, td {
            padding: 0.75rem;
        }

        thead {
            border-bottom: 0.25rem solid $brand;

            th {
                font-family: $displayFont;
                text-transform: uppercase;
                vertical-align: middle;

                span.subheader {
                    font-size: 0.8rem;
                    font-weight: 300;
                    text-transform: none;
                    display: block;
                }
            }
        }

        tbody {

            tr {
                border-bottom: 0.1rem solid $brand;
            }

            td {
                font-weight: 300;
                font-size: 1.2rem;
                vertical-align: middle;
            }
        }
    }

    .form-group {
        margin-bottom: ($sectionPadding * 2);
    }

    input, textarea {
        width: 100%;
        font-family: $bodyFont;
        font-weight: 300;
        font-size: 1.2rem;
        padding: 0.5rem;
        border-radius: 0;
    }

    label {
        font-family: $displayFont;
        text-transform: uppercase;
        display: block;
        margin-bottom: ($sectionPadding / 2);
    }

    .v-select {
        font-family: $bodyFont !important;
        font-size: 1.2rem;
        font-weight: 300;

        .dropdown-toggle {
            border-radius: 0 !important;
            padding: 1px 0 5px !important;

            .vs__actions {

                button.clear {
                    display: none;
                }
            }
        }
    }
</style>
