<template>
    <div class="info-submit row">
        <form method="post" class="col-12 offset-lg-2 col-lg-8" name="submit-new" netlify netlify-honeypot="bot-field">
            <div v-if="(message.type && message.text)" class="row">
                <div class="col-12">
                    <message-box :type="message.type" :message="message.text"/>
                </div>
            </div>

            <div class="row">
                <div class="col-12 col-sm-6">
                    <div class="form-group">
                        <label for="submit-info-email">Your Email</label>
                        <input type="email"
                               name="email"
                               id="submit-info-email"
                               v-model="form.email.value"
                               v-on:blur="validateEmail"/>
                        <form-error v-if="this.form.email.error" :message="this.form.email.error"/>
                    </div>
                </div>

                <div class="col-12 col-sm-6">
                    <div class="form-group">
                        <label for="submit-info-type">Submission Type</label>
                        <v-select id="submit-info-type"
                                  v-model="form.type.selectedType"
                                  :searchable="false"
                                  :options="form.type.typeOptions">
                        </v-select>
                        <input type="hidden" name="type">
                        <form-error v-if="this.form.type.error" :message="this.form.type.error"/>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <div class="form-group">
                        <label for="submit-info-content">Your Submission</label>
                        <textarea name="text"
                                  id="submit-info-content"
                                  rows="4"
                                  v-model="form.text.value"
                                  v-on:blur="validateText">
                        </textarea>
                        <form-error v-if="this.form.text.error" :message="this.form.text.error"/>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <div class="form-group text-right">
                        <button type="button" class="button" v-on:click="postSubmission">Submit</button>
                    </div>
                </div>
            </div>
        </form>

        <div v-if="loading" class="loading text-center">
            <div class="spinner">
                <font-awesome-icon icon="cog"/>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from 'axios'
    import MessageBox from '../messages/MessageBox'
    import FormError from './components/FormError'

    export default {
        name: 'InfoSubmission',
        components: {MessageBox, FormError},
        props: {
            type: {
                type: String,
                required: false,
                default: 'sin',
                validator: function (type) {
                    return ['sin', 'killed-game', 'death-row', 'killed-studio'].indexOf(type) !== -1
                }
            }
        },
        data: function () {
            const typeOptions = [
                {
                    label: 'Sin',
                    value: 'sin'
                }, {
                    label: 'Killed Game',
                    value: 'killed-game'
                }, {
                    label: 'Death Row',
                    value: 'death-row'
                }, {
                    label: 'Killed Studio',
                    value: 'killed-studio'
                },
            ]

            return {
                form: {
                    email: {
                        value: '',
                        error: null
                    },
                    type: {
                        typeOptions: typeOptions,
                        selectedType: typeOptions.filter((option) => {
                            return option.value === this.type
                        })[0],
                        error: null
                    },
                    text: {
                        value: '',
                        error: null
                    }
                },
                message: {
                    type: null,
                    text: null
                },
                loading: false
            }
        },
        methods: {
            validateEmail() {
                if (this.form.email.value === null || this.form.email.value.length < 1) {
                    this.$set(this.form.email, 'error', 'You must enter your email.')
                    return false
                }

                this.$set(this.form.email, 'error', null)
                return true
            },
            validateType() {
                if (['sin', 'killed-game', 'death-row', 'killed-studio'].indexOf(this.form.type.selectedType) !== -1) {
                    this.$set(this.form.type, 'error', 'Invalid selection.')
                    return false
                }

                this.$set(this.form.type, 'error', null)
                return true
            },
            validateText() {
                if (this.form.text.value === null || this.form.text.value.length < 1) {
                    this.$set(this.form.text, 'error', 'You must enter a submission.')
                    return false
                }

                this.$set(this.form.text, 'error', null)
                return true
            },
            encode(data) {
                return Object.keys(data)
                    .map(
                        key => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`
                    )
                    .join('&');
            },
            postSubmission() {

                this.validateEmail()
                this.validateType()
                this.validateText()

                if (this.form.email.error || this.form.type.error || this.form.text.error) {

                    this.$set(this, 'message', {
                        type: 'error',
                        text: 'Please correct issues with your submission before sending.'
                    })
                    return
                }

                this.$set(this, 'message', {type: null, text: null})
                this.loading = true

                axios
                    .post(
                        '/',
                        this.encode({
                            'form-name': 'submit-new',
                            email: this.form.email.value,
                            type: this.form.type.selectedType.label,
                            text: this.form.text.value
                        }),
                        {
                            header: {'Content-Type': 'application/x-www-form-urlencoded'}
                        }
                    )
                    .then((response) => {

                        this.$set(this, 'message', response.status === 200
                            ? {type: 'success', text: 'Your submission was successfully sent!'}
                            : {type: 'error', text: 'An error occurred with your submission.'}
                        )

                        this.loading = false
                    })
                    .catch(() => {
                        this.$set(this, 'message', {type: 'error', text: 'An error occurred with your submission.'});
                    });
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import '../../assets/scss/variables';

    .info-submit {
        margin-top: ($sectionPadding * 3);
        position: relative;
    }

    .loading {
        background-color: $white;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        .spinner {
            position: absolute;
            top: 30%;
            left: 50%;
            transform: translateX(-50%) translateY(-50%);

            svg {
                position: absolute;
                font-size: 4rem;
                color: $lightGrey;
                animation: spin 4s infinite linear;
            }
        }
    }

    @keyframes spin {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(360deg);
        }
    }

    .message {
        margin-top: -$sectionPadding;
        margin-bottom: $sectionPadding;
    }

    textarea {
        margin-bottom: -3px;
    }
</style>
