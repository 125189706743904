import { render, staticRenderFns } from "./CalloutBox.vue?vue&type=template&id=6260e002&scoped=true&"
import script from "./CalloutBox.vue?vue&type=script&lang=js&"
export * from "./CalloutBox.vue?vue&type=script&lang=js&"
import style0 from "./CalloutBox.vue?vue&type=style&index=0&id=6260e002&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6260e002",
  null
  
)

component.options.__file = "CalloutBox.vue"
export default component.exports