/*
|--------------------------------------------------------------------------
| Page Imports
|--------------------------------------------------------------------------
|
| Import the pages to tie to our routes.
|
*/
import Home from './components/pages/Home.vue'
import Sins from './components/pages/Sins.vue'
import KillCount from './components/pages/KillCount.vue'
import GamesKilled from './components/pages/kill_count/GamesKilled.vue'
import DeathRow from './components/pages/kill_count/DeathRow'
import StudiosKilled from './components/pages/kill_count/StudiosKilled.vue'

/*
|--------------------------------------------------------------------------
| Routes
|--------------------------------------------------------------------------
|
| Configure and export our routes.
|
*/
export default [
    {
        path: '/',
        component: Home,
        meta: {
            title: 'Home | EASins',
            metaTags: [
                {
                    name: 'description',
                    content: 'A compendium of wrongdoing from one of gaming\'s worst companies.'
                },
                {
                    property: 'og:description',
                    content: 'A compendium of wrongdoing from one of gaming\'s worst companies.'
                }
            ]
        }

    },
    {
        path: '/sins/:page?',
        component: Sins,
        props: transformPageToInteger,
        meta: {
            title: 'Sins | EASins',
            metaTags: [
                {
                    name: 'description',
                    content: 'Every truly awful act in EA\'s long history of misdeeds.'
                },
                {
                    property: 'og:description',
                    content: 'Every truly awful act in EA\'s long history of misdeeds.'
                }
            ]
        }
    },
    {
        path: '/kill-count',
        component: KillCount,
        meta: {
            title: 'Kill Count | EASins',
            metaTags: [
                {
                    name: 'description',
                    content: 'A guide to every murder EA is responsible for.'
                },
                {
                    property: 'og:description',
                    content: 'A guide to every murder EA is responsible for.'
                }
            ]
        }
    },
    {
        path: '/kill-count/games/:page?',
        component: GamesKilled,
        props: transformPageToInteger,
        meta: {
            title: 'Games Killed | EASins',
            metaTags: [
                {
                    name: 'description',
                    content: 'You\'re not playing these games. The servers are gone and if you bought it, tough.'
                },
                {
                    property: 'og:description',
                    content: 'You\'re not playing these games. The servers are gone and if you bought it, tough.'
                }
            ]
        }
    },
    {
        path: '/kill-count/death-row/:page?',
        component: DeathRow,
        props: transformPageToInteger,
        meta: {
            title: 'Games on Death Row | EASins',
            metaTags: [
                {
                    name: 'description',
                    content: 'When they stop being profitable, EA will pull the plug, and you\'ll never play these games again.'
                },
                {
                    property: 'og:description',
                    content: 'When they stop being profitable, EA will pull the plug, and you\'ll never play these games again.'
                }
            ]
        }
    },
    {
        path: '/kill-count/studios/:page?',
        component: StudiosKilled,
        props: transformPageToInteger,
        meta: {
            title: 'Studios Killed | EASins',
            metaTags: [
                {
                    name: 'description',
                    content: 'EA is responsible for the death of some of gaming\'s best studios.'
                },
                {
                    property: 'og:description',
                    content: 'EA is responsible for the death of some of gaming\'s best studios.'
                }
            ]
        }
    }
]

function transformPageToInteger(route)
{
    const page = Number.parseInt(route.params.page)
    if (Number.isNaN(page)) {
        return 1
    }
    return { page }
}

