<template>
    <nav
        :class="(navClass + ' text-left')"
        ref="nav">

        <ul>
            <li><router-link to="/" @click.native="onItemClick">Home</router-link></li>
            <li><router-link to="/sins" @click.native="onItemClick">Sins</router-link></li>
            <li><router-link to="/kill-count" @click.native="onItemClick">Kill Count</router-link></li>
        </ul>
    </nav>
</template>

<script>
    export default {
        name: "Navigation",
        props: {
            isOpen: {
                type: Boolean,
                required: true
            },
            onItemClick: {
                type: Function,
                required: true
            }
        },
        computed: {
            navClass: function () {
                return this.isOpen
                    ? 'open'
                    : ''
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import '../../assets/scss/variables';

    nav {
        background-color: $brand;
        position: absolute;
        top: ($headerContentSize + $sectionPadding + 2);
        left: 101%;
        -webkit-transition: left 0.5s ease;
        transition: left 0.5s ease;
        border: 5px solid $white;

        &.open {
            left: calc(101% - 15rem);
        }
    }

    a,
    a:focus,
    a:active {
        font-size: 2.4rem;
        line-height: 1;
        color: $textOnBrand;
        font-family: $displayFont;
        text-decoration: none;
        display: block;
        padding: ($sectionPadding);
        white-space: nowrap;
    }

    a:hover,
    a.router-link-exact-active {
        background-color: $brandHover;
    }
</style>
