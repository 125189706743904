<template>
    <button
        :class="buttonClass"
        @click="onClick">
        <i></i>
    </button>
</template>

<script>
    export default {
        name: "NavButton",
        props: {
            isOpen: {
                type: Boolean,
                required: true
            },
            onClick: {
                type: Function,
                required: true
            }
        },
        computed: {
            buttonClass: function () {
                return this.isOpen
                    ? 'open'
                    : ''
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import '../../assets/scss/variables';

    $menuSpeed: 0.1s;

    button {
        display: inline-block;
        position: relative;
        height: $headerContentSize;
        width: $headerContentSize;
        border: none;
        background-color: $white;
        cursor: pointer;
        user-select: none;
        transition: background $menuSpeed;

        i {
            position: relative;
            display: block;
            height: $headerContentSize * 0.15;
            background-color: $brand;
            font-style: normal;
            transition: background $menuSpeed;

            &:after, &:before {
                content: '';
                display: block;
                position: absolute;
                width: 100%;
                height: $headerContentSize * 0.15;
                background-color: $brand;
                transition: top $menuSpeed ($menuSpeed * 2) ease, transform $menuSpeed ease;
            }

            &:before {
                top: -(($headerContentSize * 0.2));
            }
            &:after {
                top: (($headerContentSize * 0.2));
            }
        }
    }

    .open {
        background-color: $brand;
        transition: background $menuSpeed;

        i {

            &:before, &:after{
                top: 0;
                transition: top $menuSpeed ease, transform $menuSpeed ($menuSpeed * 2) ease;
                transform: rotate(45deg) translate(0);
                background-color: $white;
            }

            &:after{
                transform: rotate(-45deg);
                background-color: $white;
            }
        }
    }

    button:focus {
        outline: 0;
    }
</style>
