/**
 * @see https://gist.github.com/kottenator/9d936eb3e4e3c3e02598
 */
export default function paginate(c, m, hrefCallback) {
    var current = c,
        last = m,
        delta = 2,
        left = current - delta,
        right = current + delta + 1,
        range = [],
        rangeWithDots = [],
        l;

    for (let i = 1; i <= last; i++) {
        if (i == 1 || i == last || i >= left && i < right) {
            range.push({
                display: i,
                page: i,
                href: hrefCallback(i),
                current: i == current
            });
        }
    }

    for (let i of range) {
        if (l) {
            if (i.display - l === 2) {
                rangeWithDots.push({
                    display: l + 1,
                    page: i,
                    href: hrefCallback(l + 1),
                    current: false
                });
            } else if (i.display - l !== 1) {
                rangeWithDots.push({
                    display: '...',
                    page: null,
                    href: null,
                    current: false
                });
            }
        }
        rangeWithDots.push(i);

        l = i.display;
    }

    return rangeWithDots;
}