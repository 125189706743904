<template>
    <div class="page">
        <section class="page-header text-center">
            <img src="../../assets/images/killcount.png" />
            <h1>Kill Count</h1>
        </section>

        <section class="games-killed brand text-center">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <h2>
                            <span v-if="gamesKilledTotal">{{ gamesKilledTotal }}</span>
                            <span v-else>---</span>
                            Games Killed
                        </h2>
                        <h3>You're not playing these games. The servers are gone and if you bought it, tough.</h3>

                        <games-killed-table :games="gamesKilled" />

                        <router-link to="/kill-count/games" class="button-brand">
                            All Killed Games
                        </router-link>
                    </div>
                </div>
            </div>
        </section>

        <section class="death-row brand text-center">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <h2>
                            <span v-if="gamesOnDeathRowTotal">{{ gamesOnDeathRowTotal }}</span>
                            <span v-else>---</span>
                            Games on Death Row
                        </h2>
                        <h3>When they stop being profitable, EA will pull the plug, and you'll never play these games again.</h3>

                        <death-row-table :games="gamesOnDeathRow" />

                        <router-link to="/kill-count/death-row" class="button-brand">
                            All Games on Death Row
                        </router-link>
                    </div>
                </div>
            </div>
        </section>

        <section class="studios-killed text-center">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <h2>
                            <span v-if="studiosKilledTotal">{{ studiosKilledTotal }}</span>
                            <span v-else>---</span>
                            Studios Killed
                        </h2>
                        <h3>EA is responsible for the death of some of gaming's best studios.</h3>

                        <studios-killed-table :studios="studiosKilled" />

                        <router-link to="/kill-count/studios" class="button">
                            All Studios Killed
                        </router-link>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    import GamesKilledTable from '../tables/GamesKilledTable'
    import DeathRowTable from '../tables/DeathRowTable'
    import StudiosKilledTable from '../tables/StudiosKilledTable'
    import EaSinsApi from '../../services/EaSinsApi'

    export default {
        name: "KillCount",
        components: { GamesKilledTable, DeathRowTable, StudiosKilledTable },
        data: function() {
            return {
                gamesKilledTotal: null,
                gamesKilled: [],
                gamesOnDeathRowTotal: null,
                gamesOnDeathRow: [],
                studiosKilledTotal: null,
                studiosKilled: []
            }
        },
        created: function () {
            EaSinsApi.getGamesKilled({per_page: 3})
                .then((response) => {
                    this.gamesKilledTotal = response.data.meta.total
                    this.gamesKilled = response.data.data
                })

            EaSinsApi.getGamesOnDeathRow({per_page: 3})
                .then((response) => {
                    this.gamesOnDeathRowTotal = response.data.meta.total
                    this.gamesOnDeathRow = response.data.data
                })

            EaSinsApi.getStudiosKilled({per_page: 3})
                .then((response) => {
                    this.studiosKilledTotal = response.data.meta.total
                    this.studiosKilled = response.data.data
                })
        }
    }
</script>

<style lang="scss" scoped>
    @import '../../assets/scss/variables.scss';

    h2 {
        span {
            display: block;
            font-size: 3rem;
        }
    }

    table {
        margin-top: ($sectionPadding * 2);
        margin-bottom: ($sectionPadding * 2);
    }

    table + .button, table + .button-brand {
        margin-bottom: $sectionPadding;
    }
</style>
