<template>
    <div v-if="sins.length < 1" class="loading">
        <font-awesome-icon icon="cog" />
    </div>

    <table v-else>
        <thead>
            <tr>
                <th>Date</th>
                <th>Sin</th>
                <th>Details</th>
            </tr>
        </thead>

        <tbody>
            <template v-for="sin in sins">
                <tr
                    v-on:click="toggleSinDetails(sin)"
                    :class="(openSins.includes(sin.id) ? ' open' : '')"
                    :key="sin.id + 'summary'">

                    <td>{{ formatDate(sin.date) }}</td>
                    <td>{{ sin.description }}</td>
                    <td><font-awesome-icon icon="chevron-down" /></td>
                </tr>

                <tr class="details" :key="sin.id + 'details'">
                    <td colspan="3">
                        <div>
                            <div>
                                {{ sin.text }}
                            </div>
                        </div>
                    </td>
                </tr>
            </template>
        </tbody>
    </table>
</template>

<script>
    import moment from 'moment'

    export default {
        name: "SinsTable",
        props: {
            sins: {
                type: Array,
                required: true
            }
        },
        data: function () {
            return {
                openSins: []
            }
        },
        methods: {
            formatDate(date) {
                return moment(date).format('MMMM Do, YYYY')
            },
            toggleSinDetails(sin) {
                if(this.openSins.includes(sin.id)) {
                    this.openSins.remove(sin.id)
                }
                else {
                    this.openSins.push(sin.id)
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import '../../assets/scss/tables.scss';

    th:first-child {
        width: 25%;
    }

    th:last-child {
        width: 15%;
    }

    th:first-child, th:last-child {

        @media only screen and (max-width: 767px) {
            width: auto;
        }
    }

    tr:not(.details) {

        td:last-child svg {
            transition: transform 0.5s ease;
        }

        &:hover {
            cursor: pointer;
        }

    }

    tr.details {
        border-bottom-width: 0;

        td {
            padding: 0;

            > div {
                overflow: hidden;
                max-height: 0px;

                transition: max-height 1.5s cubic-bezier(0, 1.05, 0, 1);

                > div {
                    text-align: left;
                    padding: ($sectionPadding * 2) $sectionPadding ($sectionPadding * 2) ($sectionPadding * 2);
                    background-color: $greyBackground;
                }
            }
        }
    }

    tr.open {

        svg {
            transform: rotate(180deg);
            transition: transform 0.5s ease;
        }

        &+tr.details {
            border-bottom-width: 0.1rem;

            td > div {
                transition: max-height 2s ease;
                max-height: 50rem;
            }
        }
    }
</style>
