<template>
    <div class="callout">
        <div>
            <img v-bind:src="image" />
        </div>

        <h2>
            <slot></slot>
        </h2>

        <div>
            <router-link :to="link" class="button-brand">
                See List
            </router-link>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'CalloutBox',
        props: {
            image: {
                type: String,
                required: true
            },
            link: {
                type: String,
                required: true
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import '../assets/scss/variables.scss';

    .callout {
        text-align: center;
        margin-bottom: $sectionPadding;
        padding: $sectionPadding;
        border: 1px solid $white;

        h2 {
            color: $white;

            span {
                display: block;
            }

            span.count {
                font-size: 3rem;
            }

            span.title {
                font-size: 1.8rem;
            }

            span.subtitle {
                font-size: 1.4rem;

                &.smaller {
                    font-size: 0.87rem;
                }

                &.smallest {
                    font-size: 0.6rem;
                }
            }
        }
    }
</style>
