import { render, staticRenderFns } from "./DeathRow.vue?vue&type=template&id=15cda8fa&scoped=true&"
import script from "./DeathRow.vue?vue&type=script&lang=js&"
export * from "./DeathRow.vue?vue&type=script&lang=js&"
import style0 from "./DeathRow.vue?vue&type=style&index=0&id=15cda8fa&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "15cda8fa",
  null
  
)

component.options.__file = "DeathRow.vue"
export default component.exports