<template>
    <div class="error">
        <p>{{ message }}</p>
    </div>
</template>

<script>
    export default {
        name: 'FormError',
        props: {
            message: {
                type: String,
                required: true
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import '../../../assets/scss/variables';

    .error {
        background-color: $error;
        padding: ($sectionPadding / 2);
        width: 100%;

        p {
            color: $white;
            margin: 0;
            font-family: $bodyFont;
            font-weight: 300;
            font-size: 0.9rem;
        }
    }
</style>
