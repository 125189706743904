import axios from 'axios'
import apiConfig from '../config/api'

/**
 * @property axios { AxiosInstance }
 */
class EaSinsApi {

    /**
     * @return { void }
     */
    constructor()
    {
        this.axios = axios.create({
            baseURL: apiConfig.url
        })
    }

    /**
     *
     * @param params { object }
     * @returns { AxiosPromise<any> }
     */
    getSinsCount({ ...params })
    {
        return this.axios.get('/countSins', {params: params})
    }

    /**
     *
     * @param params { object }
     * @returns { AxiosPromise<any> }
     */
    getSins({ ...params })
    {
        return this.axios.get('/listSins', {params: params})
    }

    /**
     *
     * @param params { object }
     * @returns { AxiosPromise<any> }
     */
    getGamesKilledCount({ ...params })
    {
        return this.axios.get('/countGamesKilled', {params: params})
    }

    /**
     * @param params { object }
     * @returns { AxiosPromise<any> }
     */
    getGamesKilled({ ...params })
    {
        return this.axios.get('/listGamesKilled', {params: params})
    }

    /**
     *
     * @param params { object }
     * @returns { AxiosPromise<any> }
     */
    getGamesOnDeathRowCount({ ...params })
    {
        return this.axios.get('/countGamesOnDeathRow', {params: params})
    }

    /**
     * @param params { object }
     * @returns {AxiosPromise<any>}
     */
    getGamesOnDeathRow({ ...params })
    {
        return this.axios.get('/listGamesOnDeathRow', {params: params})
    }

    /**
     *
     * @param params { object }
     * @returns { AxiosPromise<any> }
     */
    getStudiosKilledCount({ ...params })
    {
        return this.axios.get('/countStudiosKilled', {params: params})
    }

    /**
     * @param params { object }
     * @returns {AxiosPromise<any>}
     */
    getStudiosKilled({ ...params })
    {
        return this.axios.get('/listStudiosKilled', {params: params})
    }

    /**
     * @param params { object }
     * @returns {AxiosPromise<any>}
     */
    postSubmission({ ...params })
    {
        return this.axios.post('/submit', params)
    }
}

const api =  new EaSinsApi()
export default api
