<template>
    <div class="page">
        <breadcrumbs :links="[
                {url: '/kill-count', text: 'Kill Count'},
                {url: '/kill-count/studios', text: 'Studios Killed'}
            ]" />

        <section class="page-header text-center">
            <img src="../../../assets/images/studioskilled_large.png" />
            <h1>Studios Killed</h1>
        </section>

        <section class="games-killed text-center brand">
            <div class="row">
                <div class="container">
                    <div class="col-12">
                        <studios-killed-table :studios="studiosKilled" />
                        <pagination
                            :page="page"
                            :max="maxPages"
                            :link-callback="createPageRoute"
                            :prev-link-callback="createPrevPageLink"
                            :next-link-callback="createNextPageLink" />
                    </div>
                </div>
            </div>
        </section>

        <section class="submit-sin">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <h2 class="text-center">Missing Something?</h2>
                        <h3 class="text-center">Submit a missing studio using the form below.</h3>
                        <info-submission type="killed-studio" />
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    // configs
    import paginationConfig from '../../../config/pagination'

    // services
    import EaSinsApi from '../../../services/EaSinsApi'

    // components
    import Breadcrumbs from '../../navigation/Breadcrumbs'
    import StudiosKilledTable from '../../tables/StudiosKilledTable'
    import Pagination from '../../navigation/Pagination'
    import InfoSubmission from '../../forms/InfoSubmission'

    export default {
        name: "StudiosKilled",
        components: { Breadcrumbs, StudiosKilledTable, Pagination, InfoSubmission },
        props: {
            page: {
                type: Number,
                default: 1,
                optional: true
            }
        },
        data: function() {
            return {
                studiosKilled: [],
                maxPages: 0
            }
        },
        created: function () {
            this.updateStudios()
        },
        methods: {
            createPageRoute: function (page) {
                return '/kill-count/studios/' + page.toString()
            },
            createPrevPageLink: function (currentPage) {
                return '/kill-count/studios/' + (currentPage - 1).toString()
            },
            createNextPageLink: function (currentPage) {
                return  '/kill-count/studios/' + (currentPage + 1).toString()
            },
            updateStudios: function () {
                EaSinsApi.getStudiosKilled({per_page: paginationConfig.studiosKilled, page: this.page})
                    .then((response) => {
                        this.studiosKilled = response.data.data
                        this.maxPages = response.data.meta.last_page
                    })
            }
        },
        watch: {
            page: function () {
                this.studiosKilled = []
                this.updateStudios()
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import '../../../assets/scss/variables.scss';

    h2 {
        span {
            display: block;
            font-size: 3rem;
        }
    }

    table {
        margin-bottom: ($sectionPadding * 2);
    }
</style>
