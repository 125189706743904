<template>
    <div class="page">
        <section class="page-header text-center">
            <img src="../../assets/images/killcount.png" />
            <h1>Sins</h1>
        </section>

        <section class="total-sins brand text-center">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <sins-table :sins="sins" />
                        <pagination
                            :page="page"
                            :max="maxPages"
                            :link-callback="createPageRoute"
                            :prev-link-callback="createPrevPageLink"
                            :next-link-callback="createNextPageLink" />
                    </div>
                </div>
            </div>
        </section>

        <section class="submit-sin">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <h2 class="text-center">Missing Something?</h2>
                        <h3 class="text-center">Submit a missing Sin using the form below.</h3>
                        <info-submission type="sin" />
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    // configs
    import paginationConfig from '../../config/pagination'

    // services
    import EaSinsApi from '../../services/EaSinsApi'

    // components
    import SinsTable from '../tables/SinsTable'
    import Pagination from '../navigation/Pagination'
    import InfoSubmission from '../forms/InfoSubmission'

    export default {
        name: "Sins",
        components: { SinsTable, Pagination, InfoSubmission },
        props: {
            page: {
                type: Number,
                default: 1,
                optional: true
            }
        },
        data: function() {
            return {
                sins: [],
                maxPages: 0
            }
        },
        created: function () {
            this.updateSins()
        },
        methods: {
            createPageRoute: function (page) {
                return '/sins/' + page.toString()
            },
            createPrevPageLink: function (currentPage) {
                return '/sins/' + (currentPage - 1).toString()
            },
            createNextPageLink: function (currentPage) {
                return  '/sins/' + (currentPage + 1).toString()
            },
            updateSins: function () {
                EaSinsApi.getSins({per_page: paginationConfig.sins, page: this.page})
                    .then((response) => {
                        this.sins = response.data.data
                        this.maxPages = response.data.meta.last_page
                    })
            }
        },
        watch: {
            page: function () {
                this.sins = []
                this.updateSins()
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import '../../assets/scss/variables.scss';

    h2 {
        span {
            display: block;
            font-size: 3rem;
        }
    }

    table + .button, table + .button-brand {
        margin-bottom: $sectionPadding;
    }

</style>
