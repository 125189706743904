/*
|--------------------------------------------------------------------------
| Vue Imports
|--------------------------------------------------------------------------
|
| Import the base components we need to run Vue.
|
*/
import Vue from 'vue'
import VueRouter from 'vue-router'
import { directive as onClickOutside } from 'vue-on-click-outside'
import App from './App.vue'

/*
|--------------------------------------------------------------------------
| Routes
|--------------------------------------------------------------------------
|
| Include and configure our application routes.
|
*/
import routes from './routes.js'
const router = new VueRouter({
    routes,
    scrollBehavior () {
        return { x: 0, y: 0 }
    },
    mode: 'history'
})
Vue.use(VueRouter)

import pageMeta from './middleware/PageMeta'
router.beforeEach(pageMeta)

/*
|--------------------------------------------------------------------------
| Vue Directives
|--------------------------------------------------------------------------
|
| Add any directives or mixins.
|
*/
Vue.directive('on-click-outside', onClickOutside)

/*
|--------------------------------------------------------------------------
| Vue Config
|--------------------------------------------------------------------------
|
| Configure our Vue application.
|
*/
Vue.config.productionTip = false

/*
|--------------------------------------------------------------------------
| Vue Components
|--------------------------------------------------------------------------
|
| Additional Vue components.
|
*/
import VueSpriteAnimator from 'vue-sprite-animator'
Vue.use(VueSpriteAnimator)

import { library } from '@fortawesome/fontawesome-svg-core'
import { faChevronRight, faChevronLeft, faChevronDown, faCog } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
library.add(faChevronRight, faChevronLeft, faCog, faChevronDown)
Vue.component('font-awesome-icon', FontAwesomeIcon)

import vSelect from 'vue-select'
Vue.component('v-select', vSelect)

/*
|--------------------------------------------------------------------------
| Globals
|--------------------------------------------------------------------------
|
| Define any globals, prototypes, etc.
|
*/
require('./prototypes.js')

/*
|--------------------------------------------------------------------------
| Render
|--------------------------------------------------------------------------
|
| Render our Vue application.
|
*/
new Vue({
    render: h => h(App),
    mounted: () => document.dispatchEvent(new Event("x-app-rendered")),
    router
}).$mount('#app')
