<template>
    <ul v-if="max > 1" class="pagination">
        <li v-if="page > 1">
            <router-link :to="prevLinkCallback(page)">
                <font-awesome-icon class="chevron" icon="chevron-left" />
            </router-link>
        </li>

        <li v-for="paged in pages" :key="paged.href">
            <router-link v-if="paged.href" :to="paged.href" :class="paged.current ? 'current' : ''">
                {{ paged.display }}
            </router-link>
            <span v-else>{{ paged.display }}</span>
        </li>

        <li v-if="page < max">
            <router-link :to="nextLinkCallback(page)">
                <font-awesome-icon class="chevron" icon="chevron-right" />
            </router-link>
        </li>
    </ul>
</template>

<script>
    import paginate from '../../services/Paginator.js'

    export default {
        name: "Pagination",
        props: {
            page: {
                type: Number,
                required: true
            },
            max: {
                type: Number,
                required: true
            },
            linkCallback: {
                type: Function,
                required: true
            },
            prevLinkCallback: {
                type: Function,
                required: true
            },
            nextLinkCallback: {
                type: Function,
                required: true
            },
        },
        data: function () {
            return {
                pages: []
            }
        },
        methods: {
            updatePagination: function () {
                this.pages = paginate(
                    this.page,
                    this.max,
                    this.linkCallback
                )
            }
        },
        watch: {
            page: function () {
                this.updatePagination()
            },
            max: function () {
                this.updatePagination()
            }
        },
        mounted: function () {
            this.updatePagination()
        }
    }
</script>

<style lang="scss" scoped>
    @import '../../assets/scss/variables';

    ul {
        display: block;
        margin-bottom: $sectionPadding;

        li {
            display: inline-block;
            text-align: center;

            a {
                font-family: $bodyFont;
                color: $white;
                text-decoration: none;
                background-color: $blue;
                padding: ($sectionPadding / 3);
                margin: 0 ($sectionPadding / 3);
                min-width: 1.75rem;
                display: inline-block;

                &.current {
                    background-color: $darkBlue;
                }
            }
        }
    }
</style>
