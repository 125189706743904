<template>
    <div v-if="games.length < 1" class="loading">
        <font-awesome-icon icon="cog" />
    </div>

    <table v-else>
        <thead>
            <tr>
                <th>Platform</th>
                <th>Game</th>
                <th>Released</th>
                <th>
                    Est. Shutdown Date
                    <span class="subheader">(based on average lifetime of killed games)</span>
                </th>
            </tr>
        </thead>

        <tbody>
            <tr v-for="game in games" :key="game.id">
                <td>{{ game.platforms.join(', ') }}</td>
                <td>{{ game.name }}</td>
                <td>{{ formatDate(game.released) }}</td>
                <td>{{ formatDate(game.estimated_shutdown) }}</td>
            </tr>
        </tbody>
    </table>
</template>

<script>
    import moment from 'moment'

    export default {
        name: "DeathRowTable",
        props: {
            games: Array
        },
        methods: {
            formatDate(date) {
                return moment(date).format('MMMM Do, YYYY')
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import '../../assets/scss/tables.scss';

    th:first-child {
        width: 10%;

        @media only screen and (max-width: 767px) {
            width: auto;
        }
    }
</style>
